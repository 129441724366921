<template>
  <div class="disclaimer__wrapper">
    <div
      ref="hoverElement"
      v-focusable
      class="disclaimer__hover-content"
      v-observe-visibility="hoverVisibilityOptions"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @click="onClick"
      @focus="onMouseEnter"
      @blur="onMouseLeave"
    >
      <slot name="activator-content">
        <span class="disclaimer__text font-sans">Disclaimer</span>
      </slot>
    </div>

    <client-only>
      <Transition name="fade">
        <disclaimer-popup
          v-if="isPopupVisible"
          v-bind="popupProps"
          @close="hidePopup"
        >
          <slot name="content">
            <disclaimer-content />
          </slot>
        </disclaimer-popup>
      </Transition>
    </client-only>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import DisclaimerPopup from './DisclaimerPopup'
import DisclaimerContent from './DisclaimerContent'
import { OBSERVER_CALLBACK_DEFAULT_THROTTLE } from 'enums/mutation-observer'
import {
  DESKTOP_POPUP_SETTINGS,
  MOBILE_POPUP_SETTINGS,
  LAYOUT_SETTINGS,
  LAYOUT_ORDERING,
  LAYOUT_AREA_SIZE_FN
} from './enums'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

export default {
  name: 'ADisclaimer',
  mixins: [hydrationHelpers],
  components: { DisclaimerPopup, DisclaimerContent },
  props: {
    layoutOrdering: propValidator([PROP_TYPES.ARRAY], false, LAYOUT_ORDERING),
    topSpaceOffset: propValidator([PROP_TYPES.NUMBER], false, 0),
    bottomSpaceOffset: propValidator([PROP_TYPES.NUMBER], false, 0),
    popupSettingsByBreakpoint: propValidator([PROP_TYPES.OBJECT], false)
  },
  data() {
    return {
      isPopupVisible: false,
      hoverElementPositionData: {
        leftSpace: 0,
        rightSpace: 0,
        topSpace: 0,
        bottomSpace: 0
      },
      POPUP_SETTINGS_BY_BREAKPOINT: {
        [this.$breakpoint.aboveDesktopXl]: DESKTOP_POPUP_SETTINGS,
        [this.$breakpoint.mobile]: MOBILE_POPUP_SETTINGS
      },
      popupPosition: {
        top: null,
        bottom: null,
        left: null,
        right: null
      },
      hoverVisibilityOptions: {
        callback: visibility => {
          if (visibility) return

          this.hidePopup()
        },
        throttle: OBSERVER_CALLBACK_DEFAULT_THROTTLE
      }
    }
  },
  computed: {
    popupSettings() {
      if (!process.client) return

      return this.$_hydrationHelpers_getValueByBreakpoint(
        this.popupSettingsByBreakpoint || this.POPUP_SETTINGS_BY_BREAKPOINT
      )
    },
    popupProps() {
      if (!process.client || !this.popupSettings) return

      return {
        ...this.popupPosition,
        width: this.popupSettings.modalWidth,
        height: this.popupSettings.modalHeight
      }
    },
    popupActivatorProps() {
      if (!process.client) return null

      const hoverElement = this.$refs.hoverElement
      if (!hoverElement) return null

      return {
        popupActivatorWidth: hoverElement.clientWidth,
        popupActivatorHeight: hoverElement.clientHeight
      }
    }
  },
  watch: {
    $_hydrationHelpers_windowWidth: {
      handler() {
        this.hidePopup()
      }
    }
  },
  methods: {
    onMouseEnter() {
      if (this.$_hydrationHelpers_isLayoutMobile) return

      this.showPopup()
    },
    onMouseLeave() {
      if (this.$_hydrationHelpers_isLayoutMobile) return

      this.hidePopup()
    },
    onClick() {
      if (!this.$_hydrationHelpers_isLayoutMobile) return

      this.showPopup()
    },
    showPopup() {
      this.calculatePosition()
      this.isPopupVisible = true
    },
    hidePopup() {
      this.isPopupVisible = false
    },
    calculatePosition() {
      this.setHoverElementPositionData()

      const currentLayout = this.getLayoutByPosition({
        ...this.popupSettings,
        ...this.hoverElementPositionData,
        ...(this.popupActivatorProps || {})
      })
      this.setPopupPositionByLayout(currentLayout)
    },
    setHoverElementPositionData() {
      const hoverElement = this.$refs.hoverElement
      if (!hoverElement) return

      const { top, right, bottom, left } = hoverElement.getBoundingClientRect()

      this.hoverElementPositionData = {
        leftSpace: left,
        topSpace: top - this.topSpaceOffset,
        rightSpace:
          this.$_hydrationHelpers_windowWidth -
          right -
          this.$helper.getScrollBarWidth(),
        bottomSpace:
          this.$_hydrationHelpers_windowHeight - bottom - this.bottomSpaceOffset
      }
    },
    getLayoutByPosition(positionData) {
      const layoutKey = this.layoutOrdering.find(layout => {
        const { validationFn } = LAYOUT_SETTINGS[layout]

        return validationFn(positionData)
      })

      return layoutKey || this.getLayoutByMaxAreaSize(positionData)
    },
    getLayoutByMaxAreaSize(positionData) {
      const layoutOrderingShallowCopy = [...this.layoutOrdering]

      return layoutOrderingShallowCopy.sort((a, b) => {
        const aAreaSize = LAYOUT_AREA_SIZE_FN[a](positionData)
        const bAreaSize = LAYOUT_AREA_SIZE_FN[b](positionData)

        if (aAreaSize > bAreaSize) return -1

        if (bAreaSize > aAreaSize) return 1

        return 0
      })[0]
    },
    setPopupPositionByLayout(layout) {
      if (!layout || !this.popupSettings) return

      const layoutSettings = LAYOUT_SETTINGS[layout]
      const { top, bottom, left, right } = layoutSettings.getModalPositionFn({
        ...this.popupSettings,
        ...this.popupActivatorProps
      })

      this.popupPosition = {
        top: top,
        bottom: bottom,
        left: left,
        right: right
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disclaimer__wrapper {
  position: relative;
  z-index: $z-index-disclaimer;

  .disclaimer__hover-content {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .disclaimer__text {
    color: $c--disabled-text;
    font-size: 14px;
    line-height: 18px;
    margin-inline-end: 8px;
  }

  .disclaimer__info-icon {
    margin-left: 5px;
    fill: $c--disabled-text;
  }
}

.fade-enter-active {
  animation: fade-in 0.3s;
}

.fade-leave-active {
  animation: fade-in 0.3s reverse;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
