<template>
  <div class="a-top-brokers__wrapper">
    <div class="a-top-brokers__title-disclaimer-sponsored-container">
      <p class="a-top-brokers__title text-body font-sans bold">
        Top Brokers
      </p>
      <div class="a-top-brokers__disclaimer-sponsored-container">
        <a-disclaimer
          :layout-ordering="$options.consts.disclaimerLayoutOrdering"
          :popup-settings-by-breakpoint="disclaimerPopupSettingsByBreakpoint"
          :top-space-offset="$options.consts.disclaimerTopSpaceOffset"
        >
          <template v-slot:activator-content
            ><div class="a-top-brokers__disclaimer-icon-text-container">
              <a-icon
                :icon="$options.consts.ICON.WARNING"
                :width="12"
                :height="8"
                original
              /><span class="a-top-brokers__disclaimer-label font-sans"
                >General Risk Warning</span
              >
            </div></template
          >
          <template v-slot:content>
            <div class="a-top-brokers__disclaimer-text">
              {{ $options.consts.DISCLAIMER_TEXT }}
            </div>
          </template>
        </a-disclaimer>
        <p class="a-top-brokers__sponsored-label font-sans">
          Sponsored
        </p>
      </div>
    </div>
    <div class="a-top-brokers__banners-wrapper">
      <div
        v-for="(broker, index) in topBrokers"
        :key="`${broker.Id}-${index}`"
        class="a-top-brokers__banner-container"
      >
        <a-image
          :src="broker.Logo.URL"
          :url="broker.Url"
          :aspect-ratio="$options.consts.aspectRatios.noRatio"
          :container-aspect-ratio="
            $options.consts.aspectRatios.flDirectoryCompanyLogo
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { aspectRatios } from 'enums/aspectRatios'
import { mapGetters } from 'vuex'
import { LAYOUT as DISCLAIMER_LAYOUT } from 'shared/ADisclaimer/enums'
import AIcon, { ICON } from 'shared/AIcon'
import ADisclaimer from 'shared/ADisclaimer/index.vue'

const DISCLAIMER_TEXT =
  'General Risk Warning: CFDs are leveraged products. Trading in CFDs carries a high level of risk thus may not be appropriate for all investors. The investment value can both increase and decrease and the investors may lose all their invested capital. Under no circumstances shall the Company have any liability to any person or entity for any loss or damage in whole or part caused by, resulting from, or relating to any transactions related to CFDs.'

export default {
  name: 'ATopBrokers',
  components: {
    ADisclaimer,
    AIcon
  },
  consts: {
    aspectRatios,
    ICON,
    DISCLAIMER_TEXT,
    disclaimerTopSpaceOffset: -20,
    disclaimerLayoutOrdering: [
      DISCLAIMER_LAYOUT.BOTTOM_LEFT,
      DISCLAIMER_LAYOUT.TOP_LEFT,
      DISCLAIMER_LAYOUT.TOP_RIGHT,
      DISCLAIMER_LAYOUT.BOTTOM_RIGHT
    ]
  },
  data() {
    return {
      disclaimerPopupSettingsByBreakpoint: {
        [this.$breakpoint.aboveDesktopXl]: {
          modalWidth: 145,
          modalHeight: 165
        },
        [this.$breakpoint.mobile]: {
          modalWidth: 145,
          modalHeight: 165
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      topBrokers: 'layouts/topBrokers'
    })
  }
}
</script>

<style lang="scss" scoped>
$banner-spacing: 15px;
$background-color: #373737;
$disclaimer-sponsored-color: #afafaf;
$two-columns-breakpoint: 500px;

.a-top-brokers__wrapper {
  background: $background-color;
  width: auto;
  margin: 20px -20px;
  padding: 35px 20px 20px 20px;

  @include tablet {
    padding: 15px 20px 0 20px;
  }

  @include mobile {
    margin: 20px -15px;
    padding: 20px 20px 5px 20px;
  }

  .a-top-brokers__title-disclaimer-sponsored-container,
  .a-top-brokers__disclaimer-sponsored-container,
  .a-top-brokers__disclaimer-icon-text-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .a-top-brokers__title-disclaimer-sponsored-container {
    margin-bottom: 12px;

    /deep/ .disclaimer__wrapper {
      line-height: 10px;
    }
  }

  .a-top-brokers__title {
    padding-left: 10px;
    font-size: 16px;
    color: $c--white;
  }

  .a-top-brokers__disclaimer-label {
    font-size: 8px;
    color: $disclaimer-sponsored-color;
  }

  .a-top-brokers__disclaimer-text {
    font-size: 8px;
    line-height: 10px;
    color: $disclaimer-sponsored-color;
  }

  /deep/ .disclaimer-popup__wrapper {
    background-color: $c--black;
    padding: 8px;
  }

  /deep/ .disclaimer-popup__icon {
    display: none;
  }

  .a-top-brokers__sponsored-label {
    font-size: 10px;
    color: $disclaimer-sponsored-color;
    margin-left: 12px;
  }

  .a-top-brokers__banners-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-right: -#{$banner-spacing};
  }

  .a-top-brokers__banner-container {
    transition: none;
    border-radius: 6px;
    border: 0.6px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin: 0 #{$banner-spacing} 15px 0;
    width: calc((100% - #{$banner-spacing} * 4) / 4);
    background: $c--white;

    @media (max-width: $two-columns-breakpoint) {
      width: calc((100% - #{$banner-spacing} * 2) / 2);
    }
  }
}
</style>
