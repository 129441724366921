<template functional>
  <p class="disclaimer-content__wrapper font-sans">
    DISCLAIMER: FOREXLIVE™ may be compensated by the advertisers that appear on
    the website, based on your interaction with the advertisements or
    advertisers.
  </p>
</template>

<script>
export default {
  name: 'DisclaimerContent'
}
</script>

<style lang="scss" scoped>
.disclaimer-content__wrapper {
  font-size: 14px;
  line-height: 18px;
}
</style>
