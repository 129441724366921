<template>
  <div class="disclaimer-popup__wrapper" :style="popupStyles">
    <i class="disclaimer-popup__icon" @click="onClose" />
    <slot />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'DisclaimerPopup',
  props: {
    top: propValidator([PROP_TYPES.NUMBER], false),
    bottom: propValidator([PROP_TYPES.NUMBER], false),
    left: propValidator([PROP_TYPES.NUMBER], false),
    right: propValidator([PROP_TYPES.NUMBER], false),
    width: propValidator([PROP_TYPES.NUMBER]),
    height: propValidator([PROP_TYPES.NUMBER])
  },
  computed: {
    popupStyles() {
      const positionStyles = {
        ...(this.top != null ? { top: `${this.top}px` } : {}),
        ...(this.bottom != null ? { bottom: `${this.bottom}px` } : {}),
        ...(this.left != null ? { left: `${this.left}px` } : {}),
        ...(this.right != null ? { right: `${this.right}px` } : {})
      }

      return {
        ...positionStyles,
        width: `${this.width}px`,
        height: `${this.height}px`
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/global/mixins/_figures.scss';

.disclaimer-popup__wrapper {
  position: absolute;
  padding: 30px 20px 10px;
  border-radius: 3px;
  background: $c--gray-secondary;
  z-index: $z-index-disclaimer;

  .disclaimer-popup__header {
    height: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .disclaimer-popup__icon {
    top: 10px;
    right: 10px;

    @include cross(15px);
  }
}
</style>
