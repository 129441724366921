export const DESKTOP_POPUP_SETTINGS = {
  modalWidth: 290,
  modalHeight: 150
}

export const MOBILE_POPUP_SETTINGS = {
  modalWidth: 290,
  modalHeight: 150
}

/** Popup position relatively to the "Disclaimer" text **/
export const LAYOUT = {
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  TOP_CENTER: 'top-center'
}

const POPUP_INDENT = 25

function getRequiredLeftRightSpace({ modalWidth, popupActivatorWidth }) {
  return modalWidth - popupActivatorWidth
}

function getRequiredTopBottomSpace({ modalHeight, popupActivatorHeight }) {
  return modalHeight + POPUP_INDENT - popupActivatorHeight
}

function getVisibleModalWidth({
  modalWidth,
  leftRightSpace,
  popupActivatorWidth
}) {
  return Math.min(modalWidth, leftRightSpace + popupActivatorWidth)
}

function getVisibleModalHeight({ modalHeight, topBottomSpace }) {
  const requiredTopBottomSpace = getRequiredTopBottomSpace(modalHeight)

  if (topBottomSpace > requiredTopBottomSpace) {
    return modalHeight
  } else {
    const cutHeight = requiredTopBottomSpace - topBottomSpace
    return modalHeight - cutHeight
  }
}

export const LAYOUT_SETTINGS = {
  [LAYOUT.BOTTOM_RIGHT]: {
    validationFn: ({
      modalHeight,
      modalWidth,
      bottomSpace,
      rightSpace,
      popupActivatorWidth,
      popupActivatorHeight
    }) => {
      const requiredBottomSpace = getRequiredTopBottomSpace({
        modalHeight,
        popupActivatorHeight
      })
      const requiredRightSpace = getRequiredLeftRightSpace({
        modalWidth,
        popupActivatorWidth
      })

      return (
        bottomSpace > requiredBottomSpace && rightSpace > requiredRightSpace
      )
    },
    getModalPositionFn: () => {
      return { top: POPUP_INDENT, bottom: null, left: 0, right: null }
    }
  },
  [LAYOUT.TOP_RIGHT]: {
    validationFn: ({
      modalHeight,
      modalWidth,
      topSpace,
      rightSpace,
      popupActivatorWidth,
      popupActivatorHeight
    }) => {
      const requiredTopSpace = getRequiredTopBottomSpace({
        modalHeight,
        popupActivatorHeight
      })
      const requiredRightSpace = getRequiredLeftRightSpace({
        modalWidth,
        popupActivatorWidth
      })

      return topSpace > requiredTopSpace && rightSpace > requiredRightSpace
    },
    getModalPositionFn: () => {
      return { top: null, bottom: POPUP_INDENT, left: 0, right: null }
    }
  },
  [LAYOUT.BOTTOM_LEFT]: {
    validationFn: ({
      modalHeight,
      modalWidth,
      bottomSpace,
      leftSpace,
      popupActivatorWidth,
      popupActivatorHeight
    }) => {
      const requiredBottomSpace = getRequiredTopBottomSpace({
        modalHeight,
        popupActivatorHeight
      })
      const requiredLeftSpace = getRequiredLeftRightSpace({
        modalWidth,
        popupActivatorWidth
      })

      return bottomSpace > requiredBottomSpace && leftSpace > requiredLeftSpace
    },
    getModalPositionFn: () => {
      return { top: POPUP_INDENT, bottom: null, left: null, right: 0 }
    }
  },
  [LAYOUT.TOP_LEFT]: {
    validationFn: ({
      modalHeight,
      modalWidth,
      topSpace,
      leftSpace,
      popupActivatorWidth,
      popupActivatorHeight
    }) => {
      const requiredTopSpace = getRequiredTopBottomSpace({
        modalHeight,
        popupActivatorHeight
      })
      const requiredLeftSpace = getRequiredLeftRightSpace({
        modalWidth,
        popupActivatorWidth
      })

      return topSpace > requiredTopSpace && leftSpace > requiredLeftSpace
    },
    getModalPositionFn: () => {
      return { top: null, bottom: POPUP_INDENT, left: null, right: 0 }
    }
  },
  [LAYOUT.TOP_CENTER]: {
    validationFn: ({
      modalHeight,
      modalWidth,
      topSpace,
      leftSpace,
      rightSpace,
      popupActivatorWidth,
      popupActivatorHeight
    }) => {
      const requiredTopSpace = getRequiredTopBottomSpace({
        modalHeight,
        popupActivatorHeight
      })
      const leftRightSpaceRequired = modalWidth / 2 - popupActivatorWidth / 2

      return (
        topSpace > requiredTopSpace &&
        leftSpace > leftRightSpaceRequired &&
        rightSpace > leftRightSpaceRequired
      )
    },
    getModalPositionFn: ({ modalWidth, popupActivatorWidth }) => {
      const centeredPopupLeftCoordinate = -Math.floor(
        modalWidth / 2 - popupActivatorWidth / 2
      )

      return {
        top: null,
        bottom: POPUP_INDENT,
        left: centeredPopupLeftCoordinate,
        right: null
      }
    }
  }
}

export const LAYOUT_ORDERING = [
  LAYOUT.BOTTOM_RIGHT,
  LAYOUT.TOP_RIGHT,
  LAYOUT.BOTTOM_LEFT,
  LAYOUT.TOP_LEFT
]

export const LAYOUT_AREA_SIZE_FN = {
  [LAYOUT.TOP_RIGHT]: ({ modalWidth, modalHeight, topSpace, rightSpace }) =>
    getVisibleModalHeight({ modalHeight, topBottomSpace: topSpace }) *
    getVisibleModalWidth({ modalWidth, leftRightSpace: rightSpace }),
  [LAYOUT.TOP_LEFT]: ({ modalWidth, modalHeight, topSpace, leftSpace }) =>
    getVisibleModalHeight({ modalHeight, topBottomSpace: topSpace }) *
    getVisibleModalWidth({ modalWidth, leftRightSpace: leftSpace }),
  [LAYOUT.BOTTOM_RIGHT]: ({
    modalWidth,
    modalHeight,
    bottomSpace,
    rightSpace
  }) =>
    getVisibleModalHeight({ modalHeight, topBottomSpace: bottomSpace }) *
    getVisibleModalWidth({ modalWidth, leftRightSpace: rightSpace }),
  [LAYOUT.BOTTOM_LEFT]: ({ modalWidth, modalHeight, bottomSpace, leftSpace }) =>
    getVisibleModalHeight({ modalHeight, topBottomSpace: bottomSpace }) *
    getVisibleModalWidth({ modalWidth, leftRightSpace: leftSpace }),
  [LAYOUT.TOP_CENTER]: ({
    modalWidth,
    modalHeight,
    topSpace,
    leftSpace,
    rightSpace,
    popupActivatorWidth
  }) => {
    const halfModalWidth = modalWidth / 2
    const halfPopupActivatorWidth = popupActivatorWidth / 2

    const leftSpaceFromCenter = leftSpace + halfPopupActivatorWidth
    const rightSpaceFromCenter = rightSpace + halfPopupActivatorWidth

    const visibleModalWidth =
      Math.min(leftSpaceFromCenter, halfModalWidth) +
      Math.min(rightSpaceFromCenter + halfModalWidth)

    return (
      getVisibleModalHeight({ modalHeight, topBottomSpace: topSpace }) *
      visibleModalWidth
    )
  }
}
